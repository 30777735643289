<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="box_o">
      <div class="title_o">
        平台功能架构
      </div>
      <div class="tit_o">
        JEPaaS平台采用四层架构设计
      </div>
      <div class="tit_o">
        基于PaaS层的支撑用户，可以在较少的人员和时间成本投入下完成SaaS软件的开发、部署、运营等一系列复杂的任务。
      </div>
      <div class="tab_con">
        <div class="tab_but">
          <div
            v-for="item in lists_s"
            :key="item.id"
            class="but_con"
            :style="{background:(item.active?'#e2f0fd':'rgba(239,239,239,1)')}"
            @click="butOcheng(item)"
          >
            <div class="img_but">
              <img
                v-lazy="item.img"
                alt=""
              >
            </div>
            <div
              :style="{color:(item.active?'rgba(252,44,54,1)':'#333333')}"
              class="text_but"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div
          class="toast_con"
          :class="`toast_con${indexS}`"
        >
          {{ lists_s[indexS].data }}
        </div>
      </div>
      <div class="img_o">
        <img
          v-lazy="imgo_bot"
          alt=""
        >
      </div>
    </div>
    <div class="box_s">
      <div class="title_s">
        产品生产流程
      </div>
      <div class="con_s">
        <div class="img_s">
          <img
            v-lazy="imgs_1"
            alt=""
          >
        </div>
        <div class="tit_con">
          <span class="tit1">开发环境（内部单机环境）</span>
          <span>
            <img
              v-lazy="img_arrow"
              alt=""
            >
          </span>
        </div>
        <div class="img_s">
          <img
            v-lazy="imgs_2"
            alt=""
          >
        </div>
        <div class="tit_con">
          <span>
            <img
              v-lazy="img_arrow"
              alt=""
            >
          </span>
          <span class="tit2">测试环境（内部SaaS环境）</span>
        </div>
        <div class="img_s">
          <img
            v-lazy="imgs_3"
            alt=""
          >
        </div>
        <div class="tit_con">
          <span />
          <span class="tit3">生产环境（云端SaaS环境）</span>
        </div>
      </div>
    </div>
    <div class="box_th">
      <div class="title_th">
        五大引擎
      </div>
      <div class="con_th">
        <div class="circular_box">
          数据引擎
        </div>
        <div class="circular_box">
          字典引擎
        </div>
        <div class="circular_box">
          功能引擎
        </div>
        <div class="circular_box">
          流程引擎
        </div>
        <div class="circular_box">
          APP引擎
        </div>
      </div>
      <div class="title_th">
        四大工具
      </div>
      <div
        class="con_th"
        style="width:700px;"
      >
        <div class="but_con">
          表格工具
        </div>
        <div class="but_con">
          表单工具
        </div>
        <div class="but_con">
          打印工具
        </div>
        <div class="but_con">
          BI工具
        </div>
      </div>
    </div>
    <div class="box_fo">
      <div class="top_box">
        <div class="child_fo">
          <div class="title_fo">
            软件商店
          </div>
          <div class="img_fo">
            <img
              v-lazy="imgth_l"
              alt=""
            >
          </div>
          <div class="tit_fo">
            商品界面展示
          </div>
        </div>
        <div class="child_fo">
          <div class="title_fo">
            运营监控
          </div>
          <div class="img_fo">
            <img
              v-lazy="imgth_r"
              alt=""
            >
          </div>
          <div class="tit_fo">
            运营监控界面展示
          </div>
        </div>
      </div>
      <div class="bott_box">
        <div class="title_fo">
          产品安全
        </div>
        <div class="tab_con">
          <div
            v-for="item in lists_fo"
            :key="item.id"
            class="tab_box"
            @click="toggTab(item)"
          >
            <div
              class="box_con"
              :style="{background:(item.active?'#e2f0fd':'rgba(255,255,255,1)')}"
            >
              <img v-lazy="item.imgUrl">
            </div>
            <div
              class="tab_name"
              :style="{color:(item.active?'rgb(6, 191, 241)':'#333333'),fontWeight:(item.active?'bold':'normal')}"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div
          class="toast_con"
          :class="`toast_con${indexFo}`"
        >
          <div class="toast_l">
            {{ lists_fo[indexFo].name }}
          </div>
          <div class="toast_r">
            <div v-if="lists_fo[indexFo].data.length!=7">
              {{ lists_fo[indexFo].data }}
            </div>
            <div
              v-for="items in lists_fo[indexFo].data"
              v-else
              :key="items.title"
              class="list_con"
            >
              <div>{{ items.title }}</div>
              <div class="list_text">
                {{ items.con }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import bg_fu from '@/assets/imgs/functional/bg_fu.png';
import imgo_1 from '@/assets/imgs/functional/imgo_1.png';
import imgo_2 from '@/assets/imgs/functional/imgo_2.png';
import imgo_3 from '@/assets/imgs/functional/imgo_3.png';
import imgo_4 from '@/assets/imgs/functional/imgo_4.png';
import imgo_bot from '@/assets/imgs/functional/imgo_bot.png';
import imgs_1 from '@/assets/imgs/functional/imgs_1.png';
import imgs_2 from '@/assets/imgs/functional/imgs_2.png';
import imgs_3 from '@/assets/imgs/functional/imgs_3.png';
import img_arrow from '@/assets/imgs/functional/img_arrow.png';
import imgth_l from '@/assets/imgs/functional/imgth_l.png';
import imgth_r from '@/assets/imgs/functional/imgth_r.png';
import computer_1 from '@/assets/imgs/functional/computer_1.png';
import computer_2 from '@/assets/imgs/functional/computer_2.png';
import computer_3 from '@/assets/imgs/functional/computer_3.png';
import computer_4 from '@/assets/imgs/functional/computer_4.png';
import computer_5 from '@/assets/imgs/functional/computer_5.png';

export default {
  name: 'FunctionalPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      bg_fu,
      imgo_bot,
      imgs_1,
      imgs_2,
      imgs_3,
      img_arrow,
      imgth_l,
      imgth_r,
      lists_s: [
        {
          id: 0,
          img: imgo_1,
          name: '存储层',
          active: 1,
          left: '6%',
          data: '基于硬件完成结构化数据或非结构化数据的存储，支持关系型数据库Oracle、SqlServer、MySQL，以及非关系形数据库 MongoDB、Redis ，并且支持国产数据库如 SequoiaDB。为了提高SaaS环境下的租户数据的保密性和高可用性，我们通过“数据源引擎”为每个租户开辟了一个专属的数据库空间。',
        },
        {
          id: 1,
          img: imgo_2,
          name: '平台层',
          active: 0,
          left: '6%',
          data: '整个平台层是SaaS产品的基石，平台层完成元数据的设计及结构关系的创建，完成数据的拉取及表格、表单、图形、报表的展示，完成菜单的设计、角色的创建、权限的分配等。用户通过平台层提供的各种拖拽式、点选式的工具，即可轻松完成SaaS应用的搭建工作。',
        },
        {
          id: 2,
          img: imgo_3,
          name: '服务层',
          active: 0,
          left: '6%',
          data: '应用系统能否快速的搭建靠的是平台层的工具支撑，但是否可以SaaS化实现多租户运营的模式靠的是服务层的支撑。平台把统一调度（JOB）、及时通讯（IM）、统一工作流配置（WF）等全部进行服务化分离，最终完成平台在统一多租户（SaaS）服务的功能实现。',
        },
        {
          id: 3,
          img: imgo_4,
          name: '运营层',
          active: 0,
          left: '6%',
          data: '运营层的核心服务是帮助用户对已完成的SaaS产品进行版本划分、软件商店上架、租户细分权限划分、监控租户的注册量、使用频度、计算出留存率等。并提供代金券、优惠券等多种运营机制，通过渠道商管理功能实现渠道商的分成与任务完成情况监控工作。',
        },
      ],
      indexS: 0,
      lists_fo: [
        {
          id: 1,
          name: '数据安全',
          active: true,
          imgUrl: computer_1,
          left: 'data_con1',
          data: [
            {
              title: '存储安全',
              con: '采用阿里云高可靠的云存储服务，提供99.999999999%的数据可靠性；',
            }, {
              title: '传输安全',
              con: '所有公网数据均使用https安全协议传输，AES_256_CBC进行加密。',
            }, {
              title: '操作安全',
              con: '多维度字段级权限控制，完善的功能模块操作记录',
            }, {
              title: '账号安全',
              con: '账号登录日志、复杂密码规则、超时登出等完善的账号安全体系；',
            }, {
              title: '本地备份',
              con: '支持企业随时自主备份；',
            }, {
              title: '法律保障',
              con: '签署包括安全协议的合同，法律保障； ',
            }, {
              title: '人员安全',
              con: '所有员工签署保密协议；',
            },
          ],
        },
        {
          id: 2,
          name: '网络安全',
          active: false,
          imgUrl: computer_2,
          data: '采用128位SSL加密 配备检测系统',
        },
        {
          id: 3,
          active: false,
          imgUrl: computer_3,
          data: '电信级数据中心 严格的安全审核 严密的安全监控 ',
          name: '物理安全',
        },
        {
          id: 4,
          active: false,
          imgUrl: computer_4,
          data: '严格的数据访问授权制度 规范的客户服务流程 保密协议的商务保障',
          name: '人员与流程的严格管理',
        },
        {
          id: 5,
          active: false,
          imgUrl: computer_5,
          data: '高可用的分布式服务器集群 高度冗余的硬件配备 健全的灾难防护措施 实时数据备份机制 数据归档和导出服务',
          name: ' 冗余备份与持续运行保障',
        },
      ],
      indexFo: 0,
    };
  },
  methods: {
    butOcheng(e) {
      const self = this;
      self.indexS = e.id;
      self.lists_s.map(item => (item.id == e.id ? item.active = 1 : item.active = 0));
    },
    toggTab(e) {
      const self = this;
      self.indexFo = e.id - 1;
      self.lists_fo.filter(item => (e.id === item.id ? item.active = true : item.active = false));
    },
  },
};
</script>

<style lang='less' scoped >
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .box_o {
    text-align: center;
    width: 1200px;
    margin: 0 auto;
    .title_o {
      font-size: 40px;
      font-weight: bold;
      color: rgba(0, 0, 0, 1);
      padding: 120px 0 80px 0;
    }
    .tit_o {
      font-size: 18px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 24px;
    }
    .tab_con {
      .tab_but {
        display: flex;
        justify-content: space-between;
        .but_con {
          cursor: pointer;
          width: 279px;
          height: 140px;
          background: rgba(239, 239, 239, 1);
          border-radius: 10px;
          margin: 60px 0 40px 0;
          display: flex;
          justify-content: center;
          align-items: center;
          .img_but {
            width: 60px;
            height: 60px;
            img {
              width: 60px;
              height: 60px;
            }
          }
          .text_but {
            width: 100px;
            height: 20px;
            font-size: 20px;
            font-weight: bold;
            color: rgba(252, 44, 54, 1);
            line-height: 30px;
          }
        }
      }
      .toast_con {
        width: 1100px;
        height: 193px;
        background: rgba(226, 240, 253, 1);
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 24px;
        padding: 0 50px;
        text-align: justify;
        display: flex;
        align-items: center;
      }
      .toast_con:before {
        content: "";
        display: block;
        width: 0px;
        height: 0px;
        border: 20px solid transparent;
        border-bottom-color: rgba(226, 240, 253, 1);
        position: relative;
        bottom: 60%;
      }
      .toast_con0:before {
        left: 6%;
      }
      .toast_con1:before {
        left: 34%;
      }
      .toast_con2:before {
        left: 62%;
      }
      .toast_con3:before {
        left: 90%;
      }
    }
    .img_o {
      width: 1200px;
      height: 750px;
      margin: 85px 0;
      img {
        width: 1200px;
        height: 750px;
      }
    }
  }
  .box_s {
    width: 100%;
    height: 1468px;
    background: rgba(236, 243, 249, 1);
    .title_s {
      font-size: 40px;
      font-weight: bold;
      color: rgba(48, 51, 54, 1);
      padding: 120px 0 80px 0;
    }
    .con_s {
      width: 940px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 100px;
      .img_s {
        width: 940px;
        height: 250px;
        // background-color: aqua;
        img {
          width: 940px;
          height: 250px;
        }
      }
      .tit_con {
        height: 100px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        .tit1 {
          width: 61%;
          margin-top: -15px;
          text-align: right;
        }
        .tit2 {
          width: 61%;
          margin-top: -15px;
          text-align: left;
        }
        .tit3 {
          width: 100%;
          margin-top: -15px;
          text-align: center;
        }
        img {
          width: 36px;
          height: 90px;
          margin: 0 60px;
        }
      }
    }
  }
  .box_th {
    width: 100%;
    height: 795px;
    background: rgba(29, 34, 49, 1);
    .title_th {
      font-size: 40px;
      font-family: MicrosoftYaHei-Bold;
      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      padding: 120px 0 90px 0;
    }
    .con_th {
      width: 940px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .circular_box {
        width: 110px;
        height: 110px;
        background: rgba(255, 255, 255, 1);
        border: 15px solid rgba(255, 48, 65, 1);
        border-radius: 50%;
        font-size: 18px;
        font-weight: bold;
        color: rgba(48, 51, 54, 1);
        line-height: 110px;
      }
      .but_con {
        width: 150px;
        height: 60px;
        background: rgba(255, 255, 255, 1);
        border-radius: 30px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(255, 48, 65, 1);
        line-height: 60px;
      }
    }
  }
  .box_fo {
    .top_box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .child_fo {
        .title_fo {
          font-size: 40px;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
          padding-top: 120px;
        }
        .img_fo {
          width: 580px;
          height: 811px;
          margin: 60px 0 30px 0;
          img {
            width: 580px;
            height: 811px;
          }
        }
        .tit_fo {
          font-size: 16px;
          font-weight: 400;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
        }
      }
    }
    .bott_box {
      width: 1200px;
      margin: 0 auto;
      .title_fo {
        font-size: 40px;
        font-weight: bold;
        color: rgba(0, 0, 0, 1);
        padding: 100px 0 70px 0;
      }
      .tab_con {
        display: flex;
        justify-content: space-between;
        .tab_box {
          width: 216px;
          height: 330px;
          .box_con {
            cursor: pointer;
            width: 216px;
            height: 216px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
          }
          .tab_name {
            width: 120px;
            margin: 20px auto;
            font-size: 20px;
            color: rgba(0, 0, 0, 1);
            line-height: 30px;
          }
        }
      }
      .toast_con {
        width: 1080px;
        padding: 60px;
        background: rgba(222, 235, 249, 1);
        border-radius: 10px;
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .toast_l {
          flex: 1;
          width: 130px;
          font-size: 20px;
          font-weight: bold;
          color: rgba(0, 0, 0, 1);
          line-height: 30px;
        }
        .toast_r {
          flex: 7;
          padding: 0 40px;
          font-size: 18px;
          font-weight: bold;
          color: rgba(51, 51, 51, 1);
          line-height: 24px;
          display: flex;
          flex-wrap: wrap;
          text-align: left;
          .list_con {
            margin: 15px 0;
            width: 33%;
            .list_text {
              width: 98%;
              margin: 0 auto;
              font-weight: 400;
              padding: 5px 0;
            }
          }
        }
      }
      .toast_con0::after {
        left: 3%;
      }
      .toast_con1::after {
        left: 25%;
      }
      .toast_con2::after {
        left: 48%;
      }
      .toast_con3::after {
        left: 71%;
      }
      .toast_con4::after {
        left: 94%;
      }
    }
  }
}
</style>
